export const getCardConfig = async(data) => {
    return [
        {
            column: 1,
            title: "Change Password",
            bodyType: "form",
            fields: [
                {
                    field: 'currentPassword',
                    headerName: 'Current Password',
                    required: true,
                    type: 'text',
                    dataType: 'password',
                },
                {
                    field: 'newPassword',
                    headerName: 'New Password',
                    required: true,
                    type: 'text',
                    dataType: 'password',
                },
                {
                    field: 'confirmPassword',
                    headerName: 'Confirm Password',
                    required: true,
                    type: 'text',
                    dataType: 'password'
                },
            ]
        },
        {
            column: 2,
            title: "Password Must Contain",
            bodyType: "checkList",
            config: {
                userCheck: false,
            },
            fields: [
                {field: "length", "title": "8 Characters Long"},
                {field: "upper", "title": "1 Uppercase Letter"},
                {field: "lower", "title": "1 Lowercase Letter"},
                {field: "number", "title": "1 Number"},
                {field: "special", "title": "1 Special Character"},
                {field: "match", "title": "Passwords Match"}
            ]
        }
    ]
}